<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo"></b-link>
      <!-- /Brand logo-->

      <b-col lg="8" md="8" sm="12" class="d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Create Password -->
      <b-col
        lg="4"
        md="4"
        sm="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Create Password
          </b-card-title>
          <b-card-text class="mb-2">
            Create strong password for your account
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules" v-if="tokenStatus">
            <b-form
              class="auth-reset-password-form mt-2"
              method="POST"
              @submit.prevent="validationCPForm"
            >
              <!-- password -->
              <b-form-group label="New Password" label-for="reset-password-new">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="forgotPassword"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                label="Confirm Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="forgotPasswordcPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button block type="submit" variant="primary">
                Set New Password
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login-v2' }">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- Create Password -->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BAlert,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AuthServices from "@/apiServices/AuthServices";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BAlert,

    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      showInputs: true,
      tokenStatus: false,
      resMessage: "",
      resetUserEmail: "",
      sideImg: require("@/assets/images/pages/revivify-labels.svg"),
      required,
      email,

      forgotPasswordUserEmail: "",
      forgotPasswordcPassword: "",
      forgotPassword: "",
      password1FieldType: "password",
      password2FieldType: "password",
      newPassword: "",
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },

    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  beforeMount() {
    this.loadDefault();
  },
  methods: {
    loadDefault() {
      const { token } = this.$route.query;
      if (token === undefined) {
        console.log("TOKEN_NOT_FOUND");
      } else {
        console.log("TOKEN_FOUND: ", token);
        this.verifyCreatePasswordToken(token);
      }
    },

    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },

    validationCPForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.forgotPasswordcPassword == this.forgotPassword) {
            this.newPassword = this.forgotPasswordcPassword;
          } else {
            this.toastNotificatoin(
              "Password Not Matched",
              "UserIcon",
              "warning"
            );
            return;
          }

          const { token } = this.$route.query;
          const sendData = { user_token: token, password: this.newPassword };

          AuthServices.createPassword(sendData)
            .then((response) => {
              if (response.data.status) {
                this.toastNotificatoin(
                  response.data.message,
                  "UserIcon",
                  "success"
                );
                this.$router.push("/onboarding").catch();
              } else {
                this.toastNotificatoin(
                  response.data.message,
                  "UserIcon",
                  "danger"
                );
              }
            })
            .catch((error) => {
              console.error("There was an error!", error);
            });
        }
      });
    },

    verifyCreatePasswordToken(tokenVal) {
      const sendData = { token: tokenVal };

      AuthServices.VerifyCPToken(sendData)
        .then((response) => {
          if (response.data.status) {
            this.tokenStatus = true;
          } else {
            this.tokenStatus = false;
          }

          console.log("VERIFICATOIN: ", this.tokenStatus);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },

    toastNotificatoin(message, icon, varient) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: icon,
          variant: varient,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
